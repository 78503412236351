import React from "react"
import { Helmet } from "react-helmet"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const TwitterWidget = () => {
  return (
    <Helmet>
      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </Helmet>
  )
}

const TwitterMention = ({ user, url }) => {
  return (
    <div>
      <OutboundLink
        href={`https://twitter.com/intent/tweet?screen_name=${user}&ref_src=twsrc%5Etfw`} 
        class="twitter-mention-button" 
        data-text={`about the ${url}, `} 
        data-related="" 
        data-show-count="false">
        Discuss this article with @{user}
      </OutboundLink>
    </div>
  )
}

export { TwitterWidget, TwitterMention }
