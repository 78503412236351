import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import blogStyle from "../components/blog.module.scss"
import SEO from "../components/seo"
import { pathToSeoTitle } from "../utils.tsx"

import { TwitterWidget, TwitterMention } from "../components/twitter"

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        author {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        tags
        lang
        description
        featured_image
      }
      html
    }
  }
`

const Blog = (props) => {
  return (
    <Layout>
      <TwitterWidget />
      <SEO 
        description={props.data.markdownRemark.frontmatter.description}
        lang={props.data.markdownRemark.frontmatter.lang}
        title={pathToSeoTitle(props.path)} 
      />

      <h1>{props.data.markdownRemark.frontmatter.title}</h1>
      <p className={blogStyle.blogPostMeta}>
        Published date: {props.data.markdownRemark.frontmatter.date}
      </p>
      <p className={blogStyle.blogPostMeta}>
        Tags: {
          props.data.markdownRemark.frontmatter.tags
            .map((tag) => 
                <Link to={`/blog/tag/${tag}`} key={tag}>{tag}</Link>,
            )
            .reduce((prev, curr) => [prev, ', ', curr])
        }
      </p>
      <p></p>
      <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
      <p></p>
      <TwitterMention
        user={props.data.site.siteMetadata.author.twitter}
        url={props.data.site.siteMetadata.siteUrl + props.path}
      />
    </Layout>
  )
}

export default Blog
